:root {
    --cg-secondary-color: #ccc;
    --cg-third-color: rgba(0, 0, 0, .125);
    --cg-white-color: #fff;
    --cg-black-color: #000;
    --cg-pink-color: #fc466b;
    --cg-light-pink-color: rgb(251, 211, 233);
    --cg-dark-pink-color: rgba(187, 55, 125, 1);
    --cg-dark-pink2-color: rgba(255, 0, 132, 1);
    --cg-greenOne-color: #56ab2f;
    --cg-greenTwo-color: #a8e063;
}

.tournement_btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 6px 26px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    color: var(--cg-white-color);
    transition: .5s;
    background-size: 200% auto;
    background-image: linear-gradient(to right, var(--cg-greenOne-color) 0%, var(--cg-greenTwo-color) 51%, var(--cg-greenOne-color) 100%);
}

.tournement_btn:hover {
    color: var(--cg-white-color);
    background-position: right center;
    text-decoration: none;
}

/* Card style start */
.cg_tournement_card {
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: var(--cg-white-color);
    background-clip: border-box;
    border: 1px solid var(--cg-third-color);
    border-radius: 8px;
    margin: 16px 0;
    /* margin: 16px 10px; */
    position: relative;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.cg_tournement_card:first-child {
    margin-top: 46px;
}
.cg_tournement_card:last-child {
    margin-bottom: 16px;
}

.cg_tournement_card .top_message {
    font-size: 10px;
    letter-spacing: .5px;
    position: absolute;
    top: -8px;
    left: 8px;
    display: flex;
}


.cg_tournement_card .top_message .cg_star,
.cg_tournement_card .top_message .cg_fast,
.cg_tournement_card .top_message .cg_table_player_info {
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--cg-white-color);
    background: var(--cg-light-pink-color);
    background: -moz-linear-gradient(360deg, var(--cg-light-pink-color) 0%, var(--cg-dark-pink-color) 51%);
    background: -webkit-linear-gradient(360deg, var(--cg-light-pink-color) 0%, var(--cg-dark-pink-color) 51%);
    background: linear-gradient(360deg, var(--cg-light-pink-color) 0%, var(--cg-dark-pink-color) 51%);
    border: 1px solid var(--cg-dark-pink-color);
    border-radius: 3px;
    margin-right: 4px;
}

/* Star */
.cg_tournement_card .top_message .cg_star {
    width: 14px;
    border-radius: 50%;
}

.cg_tournement_card .top_message .cg_star::before {
    content: '';
    background: var(--cg-white-color);
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
    display: inline-block;
    height: 10px;
    width: 10px;
}

.cg_tournement_card .top_message .cg_fast {
    padding: 0 6px;
}

.cg_tournement_card .top_message .cg_table_player_info {
    padding: 0 6px;
}


.cg_tournement_card_header {
    display: flex;
    align-items: center;
    padding: 12px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid var(--cg-third-color);
    background-color: var(--cg-primary-color);
}

.cg_tournement_card_header .cg_tournement_name {
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.cg_tournement_card_header .cg_tournement_name img {
    margin-right: 4px;
}

.progress {
    width: 100%;
    height: 8px;
    border-radius: 20px;
    color: var(--cg-greenOne-color);
    position: relative;
}

.progress::before {
    content: "";
    position: absolute;
    margin: 2px;
    inset: 0 100% 0 0;
    border-radius: inherit;
    background: currentColor;
    /* animation: progress-pf82op 6s infinite; */
    animation: progress-pf82op 6s forwards;
}

@keyframes progress-pf82op {
    100% {
        inset: 0;
    }
}

.cg_tournement_card_header .cg_tournement_info {
    margin-left: auto;
}

.cg_tournement_card .cg_tournement_card_body {
    padding: 12px;
}

.cg_tournement_card .inner_content {
    display: flex;
    flex: 1 1 auto;
    min-height: 1px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.cg_tournement_card .cg_tournement_card_body .cg_tournement_card_title {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cg_tournement_card .cg_tournement_card_body span.cg_pize_amount {
    font-size: 18px;
    font-weight: bold;
    line-height: 27px;
    margin: 0 6px;
}

.cg_tournement_card .cg_tournement_card_footer {
    display: flex;
    align-items: center;
    padding: 12px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: var(--cg-primary-color);
    border-top: 1px solid var(--cg-third-color);
}

.cg_tournement_card .cg_tournement_card_footer .winner_prize,
.cg_tournement_card .cg_tournement_card_footer .contest_size,
.cg_tournement_card .cg_tournement_card_footer .cg_tournement_time {
    flex-grow: 1;
    flex-basis: 0;
    position: relative;
    padding-left: 18px;
}

.cg_tournement_card .cg_tournement_card_footer .winner_prize::before,
.cg_tournement_card .cg_tournement_card_footer .contest_size::before,
.cg_tournement_card .cg_tournement_card_footer .cg_tournement_time::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 14px;
    height: 14px;
    display: inline-block;
    background-image: url("/image/deck/trophy-icon.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

}

.cg_tournement_card .cg_tournement_card_footer .cg_tournement_time::before {
    background-image: url("/image/deck/clock1-icon.svg");
}

.cg_tournement_card .cg_tournement_card_footer .contest_size::before {
    background-image: url("/image/deck/people1-icon.svg");
}