*,
*:after,
*:before {
    box-sizing: border-box;
}

:root {
    --cg-secondary-color: #ccc;
    --cg-third-color: rgba(0, 0, 0, .125);
    --cg-white-color: #fff;
}

body {
    background-color: #ecf0f1;
    /* Temp */
    font-family: 'Lato', sans-serif;
}

.cg_nav_container {
    width: 100%;
    max-width: 580px;
    height: 100vh;
    margin: 0 auto;
    background: var(--cg-white-color);
    /* Temp */
}

/* Style the tabs container */
.cg_nav_container .cg_nav,
.cg_nav_container .cg_footer_nav {
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 580px;
    z-index: 1;
    background: var(--cg-white-color);
}

.cg_nav_container ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

/* Style the individual tab */
.cg_nav_container .cg_nav_item {
    cursor: pointer;
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;
    padding: 10px;
}

/* Style the active tab */
.cg_nav_container .active_nav {
    background-color: var(--cg-secondary-color);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

/* Style the tab content */
.cg_nav_container .cg_tab_content {
    display: none;
    padding: 10px;
    background-color: var(--cg-white-color);
}
