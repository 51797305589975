
:root {
    --cg-screen-background: linear-gradient(180deg, #222222 0%, #373737 33.33%, #2F2D2D 68.75%, #060606 100%);
    --cg-secondary-color: #ccc;
    --cg-third-color: rgba(0, 0, 0, 0.125);
    --cg-white-color: #fff;
    --cg-black-color: #000;
}

*:after,
*:before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    background: var(--cg-screen-background);
    font-family: 'Poppins', sans-serif;
}
.cg_main_container {
    position: relative;
    width: 100%;
    max-width: 700px;
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

/* Modal Styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: rgba(0, 0, 0, 0.5); */
    background: linear-gradient(180deg, rgba(34, 34, 34, 0.80) 0%, rgba(55, 55, 55, 0.80) 33.33%, rgba(47, 45, 45, 0.80) 68.75%, rgba(6, 6, 6, 0.80) 100%);
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
}

.modal .modal-content {
    background: linear-gradient(180deg, #136618 0%, #2E9234 53.12%, #176D1D 100%);
    border-radius: 0px 0px 10px 10px;
    border-radius: 5px;
    max-width: 600px;
    width: 100%;
    position: relative;
}
.modal .close {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 24px;
    padding: 4px;
    font-weight: bold;
    background-color: #FFF;
    border-radius: 50%;
    transform: translate(30%, -30%);
    cursor: pointer;
}



@media screen and (max-width: 599px) {
    body::before {
        content: "Game not supported on your device. Your screen's resolution is too low.";
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background: var(--cg-screen-background);
        z-index: 9999;
        padding: 16px;
        text-align: center;
    }
    #root {
        display: none;
    }
}
