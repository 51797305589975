@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Poppins:wght@400;600&family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Poppins:wght@400;600&family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
html {
  height: 100%;
}
body {
  color: #000000;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.rc_font {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
}
:root {
    --card-width: 32px;
    --card-height: 45px;
    --cg-table-margin-bottom: 55px;
    --half-card-width: 40px;
    --half-card-height: 40px;
    --player-size: 64px;
    --timer-size: 64px;
    --border: 4px;
    --color: #eb2f06;
    --rotation: 100;

    /* Font Size */
    --font-size-lg: 12px;
    --font-size-sm: 10px;
    --font-size-xs: 8px;

    --cg-primary-color: linear-gradient(180deg, #08700F 0%, #136818 100%);
    --cg-secondary-color: #ccc;
    --cg-third-color: rgba(0, 0, 0, .125);
    --cg-white-color: #fff;
    --cg-black-color: #000;
    --cg-pink-color: #fc466b;
    --cg-light-pink-color: rgb(251, 211, 233);
    --cg-dark-pink-color: rgba(187, 55, 125, 1);
    --cg-dark-pink2-color: rgba(255, 0, 132, 1);
    --cg-greenOne-color: #56ab2f;
    --cg-greenTwo-color: #a8e063;
}


::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1); */
    /* background-color: #F5F5F5; */
    background-color: transparent;
    border-radius: 10px;
}

::-webkit-scrollbar {
    width: 4px;
    /* background-color: #F5F5F5; */
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /* background-color: #FFF; */
    /* background: linear-gradient(180deg, #136618 0%, #2E9234 53.13%, #176D1D 100%); */
    background-color: transparent;
    background: transparent;
}

.nav_btn {
    background: transparent;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-size: 23px;
    line-height: 20px;
    padding-left: 11px;
}

.nav_btn:active {
    color: #000;
}

.btn {
    font-weight: 700;
    font-size: 16px;
    letter-spacing: -0.33px;
    padding: 5px 6px;
    border: none;
    cursor: pointer;
    line-height: 21px;
    text-transform: uppercase;
    height: 100%;
    border-radius: 4px;
}

.btn-success {
    font-weight: 600;
    font-size: 14px;
    line-height: 27px;
    padding: 5px 20px;
    border-radius: 8px;
    border: 0;
    margin-left: 10px;
}

.btn-primary {
    color: #000;
    background-color: #fff;
}

.btn-secondary {
    color: #000;
    background: linear-gradient(180deg, #FAD203 0%, #DBB800 100%);
}

.btn-primary:active {
    color: #fff;
    background-color: #000;
}

.btn-secondary:hover {
    color: #fff;
    background: #000;
}

.text_white {
    color: #FFFFFF;
}

.text_dark {
    color: #000;
}

.text_danger {
    color: #e44145;
}

.text_yellow {
    color: rgba(255, 199, 0, 1);
}

.bg_dark {
    background-color: #000;
}

.bg_white {
    background-color: #fff;
}

.primary_text {
    color: #FFFFFF;
    font-size: 1.1vw;
    font-weight: 600;
    line-height: 33px;
    margin: 0;
    text-transform: uppercase;
}

.secondary_text {
    font-size: 1.5625vw;
    font-weight: 600;
    line-height: 27px;
    margin: 0;
    text-transform: capitalize;
}

.loader {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    max-width: 767px;
    width: 100%;
    height: 100%;
    z-index: 99;
    background-color: rgb(0 0 0 / 40%);
    top: 0;
}

/* Start common */
.cg_card {
    position: relative;
    display: inline-block;
    width: 32px;
    width: var(--card-width);
    height: 45px;
    height: var(--card-height);
    font: 12px 'Trebuchet MS';
    border-radius: 4px;
    background: #fff;
    border: 1px solid #000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
    text-align: left;
}

.cg_card.cg_half_card {
    width: 40px;
    width: var(--half-card-width);
    height: 40px;
    height: var(--half-card-height);
    font-size: 12px;
    font-size: var(--font-size-lg);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.cg_card:before {
    content: attr(value);
    margin-left: 12%;
}

.cg_card[value="11"]:before {
    content: 'J';
}

.cg_card[value="12"]:before {
    content: 'Q';
}

.cg_card[value="13"]:before {
    content: 'K';
}

.cg_card[value="14"]:before {
    content: 'A';
}

.cg_card.cg_card_H,
.cg_card.cg_card_D {
    color: #e44145;
}

.cg_card.cg_card_S,
.cg_card.cg_card_C {
    color: #000;
}

.cg_card .cg_card_icon {
    position: absolute;
    top: 28%;
    left: 10%;
    content: '';
    display: inline-block;
    width: 30%;
    height: 20%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.cg_card.cg_half_card .cg_card_icon {
    display: none;
}

.cg_card.cg_card_H .cg_card_icon {
    background-image: url("/image/deck/heart-icon.svg");
}

.cg_card.cg_card_D .cg_card_icon {
    background-image: url("/image/deck/diamond-icon.svg");
}

.cg_card.cg_card_S .cg_card_icon {
    background-image: url("/image/deck/spade-icon.svg");
}

.cg_card.cg_card_C .cg_card_icon {
    background-image: url("/image/deck/club-icon.svg");
}

.cg_card .cg_card_icon_big {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-30%, -10%);
            transform: translate(-30%, -10%);
    width: 100%;
    height: 50%;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 22px;
    height: 22px;
}

.cg_card.cg_half_card .cg_card_icon_big {
    top: 25%;
    left: 25%;
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
    width: 75%;
    height: 75%;
}

.cg_card .cg_deck_order {
    position: absolute;
    font-weight: 600;
    font-size: 10px;
    line-height: 27px;
    height: 19px;
    width: 19px;
    border: 1px solid #000000;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    top: 0;
    -webkit-transform: translate(0%, -70%);
            transform: translate(0%, -70%);
}

.cg_bottom_bar .cg_deck_order {
    left: 80%;
    top: 65%;
}

.cg_card.cg_card_H .cg_card_icon_big {
    background-image: url("/image/deck/heart-icon.svg");
}

.cg_card.cg_card_D .cg_card_icon_big {
    background-image: url("/image/deck/diamond-icon.svg");
}

.cg_card.cg_card_S .cg_card_icon_big {
    background-image: url("/image/deck/spade-icon.svg");
}

.cg_card.cg_card_C .cg_card_icon_big {
    background-image: url("/image/deck/club-icon.svg");
}

.cg_player {
    /* left: 0; */
    left: 4%;
    top: 10%;
    position: absolute;
    width: calc(64px + 2px);
    width: calc(var(--player-size) + 2px);
    height: calc(64px + 2px);
    height: calc(var(--player-size) + 2px);
    border-radius: 50%;
    display: inline-block;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 0px 0px 3px white inset;
}

.cg_player.cg_rounded_timer {
    /* animation: circlemask 15s .5s infinite; */
    -webkit-animation: circlemask 20s infinite;
            animation: circlemask 20s infinite;
}

.participent_blur::after {
    content: '';
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    border-radius: 50%;
    position: absolute;
    width: 69px;
    height: 69px;
}

.cg_player.cg_rounded_timer.participent_waiting:before,
.cg_player.cg_rounded_timer.participent_blur:before {
    background: #000;
}

.cg_player.cg_rounded_timer.pending_turn:before {
    background: #FFB800;
}

.cg_player.cg_rounded_timer.complete_turn:before {
    background: #fff;
}

.participent_blur .cg_player_score {
    -webkit-filter: blur(2px);
            filter: blur(2px);
}

.participent_turn {
    box-shadow: 0px 0px 60px #38FF00;
}

.participent_waiting {
    box-shadow: 0px 0px 20px 3px rgba(255, 255, 255, 0.8);
}

.cg_player_partner_field,
.cg_player.cg_player_opponent::after {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translate(-60%, -50%);
            transform: translate(-60%, -50%);
    width: 40%;
    height: 40%;
    font-weight: 600;
    font-size: 12px;
    font-size: var(--font-size-lg);
    line-height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    background-color: #fff;
    border: 3px solid #4cd137;
    z-index: 9;
}

.cg_player.cg_player_opponent::after {
    content: 'O';
    background-color: #ff4912;
    border: 1px solid #f74a1f;
}

.cg_player.bottomLeft.cg_player_partner::after {
    top: -30%;
    left: 0;
    right: auto;
}

.player_info_name {
    position: absolute;
    font-size: 12px;
    font-size: var(--font-size-lg);
    font-weight: normal;
    letter-spacing: 0.333333px;
    height: 26px;
    width: 80px;
    min-width: 60px;
    top: 100%;
    left: 50%;
    -webkit-transform: translate(-50%, -30%);
            transform: translate(-50%, -30%);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #fff;
    background: #000000;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    text-align: center;
    z-index: -10;
    margin: 0;
    padding: 8px 10px;
}

.cg_player .cg_player_score {
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translate(60%, -50%);
            transform: translate(60%, -50%);
    width: 40%;
    height: 40%;
    font-weight: 600;
    font-size: 12px;
    font-size: var(--font-size-lg);
    line-height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    background-color: #fff;
    border: 3px solid #4cd137;
    z-index: 9;
}

.cg_player .cg_player_time {
    display: none;
}

.cg_player .cg_player_time.show {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(-86%, -8%);
            transform: translate(-86%, -8%);
    width: 70%;
    height: 70%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4cd137;
    border: 1px solid #44bd32;
    z-index: -2;
}

.cg_player .cg_player_crown::after {
    content: '';
    background-image: url('/image/player_crown.svg');
    background-repeat: no-repeat;
    position: absolute;
    height: 100%;
    width: 90%;
    top: -36%;
    left: 5%;
    background-size: 100%;
}

/* End common */

.cg_container {
    margin: 0 auto;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cg_screen {
    height: 100vh;
    width: 100%;
    position: relative;
}

.cg_top_bar,
.cg_bottom_bar {
    width: 100%;
    height: 40px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    background: linear-gradient(180deg, #08700F 0%, #136818 100%);
    background: var(--cg-primary-color);
    position: absolute;
}

.cg_top_bar {
    justify-content: space-between;
    top: 0;
}

.cg_top_bar h5 {
    display: flex;
    align-items: center;
    color: #eb2f06;
    margin: 0;
}


.cg_bottom_bar {
    bottom: 0;
}

.cg_bottom_bar .cg_card.cg_half_card {
    height: 30px;
    width: 30px;
}

.bottom_bar_content {
    display: flex;
    justify-content: center;
    width: 100%;
}

.cg_bid>* {
    width: 33.33%;
}

.cg_bid {
    display: flex;
    width: 100%;
    height: 100%;
}

.bid_wrapper {
    display: flex;
    align-items: center;
}

.bid_btn_wrapper {
    display: flex;
    justify-content: center;
}

.pass_btn_wrapper {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    justify-content: flex-end;
    text-align: end;
    padding: 4px 10px;
}

.btn-add-bid {
    font-weight: 900;
    font-size: 25px;
    line-height: 27px;
    border: 0;
    cursor: pointer;
    width: 40px;
}

.btn-add-bid:active {
    background: #45A729;
}

.bid_btn_wrapper input {
    max-width: 130px;
    font-weight: 600;
    text-align: center;
    font-size: 2vw;
    border: 0;
}

.bid_btn_wrapper input:focus {
    outline: none;
}

.cg_powersuit {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.cg_powersuit_left,
.cg_powersuit_right {
    display: flex;
    grid-gap: 30px;
    gap: 30px;
    align-items: center;
}

.cg_right_btns {
    display: flex;
    align-items: center;
}

.cg_powersuit_left div {
    display: flex;
    /* align-items: center; */
    grid-gap: 20px;
    gap: 20px;
}

.cg_powersuit_left button,
.cg_powersuit_right button {
    padding: 3px 13px;
    cursor: pointer;
}

/* Modal Style */
.ReactModal__Overlay {
    background: linear-gradient(180deg, rgba(34, 34, 34, 0.80) 0%, rgba(55, 55, 55, 0.80) 33.33%, rgba(47, 45, 45, 0.80) 68.75%, rgba(6, 6, 6, 0.80) 100%) !important;
    -webkit-backdrop-filter: blur(4px) !important;
            backdrop-filter: blur(4px) !important;
}

.featureModal {
    position: absolute;
    width: 500px;
    height: auto;
    margin: auto;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 8px;
    background: linear-gradient(180deg, #136618 0%, #2E9234 53.13%, #176D1D 100%);
    outline: none;
}

.featureModal .cg_row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
}

.featureModal .cg_col {
    flex: 1 0;
}

.featureModal .cg_col_auto {
    flex: 0 0 auto;
    width: auto;
}

.featureModal.modal_content {
    padding: 0;
}

.featureModal.modal_content .avatar_container {
    display: flex;
    grid-gap: 30px;
    gap: 30px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 10px 0;
    /* max-width: 400px; */
    /* margin: 0 auto; */
}

.featureModal.modal_content .player_avatar {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 64px;
    width: var(--player-size);
    height: 64px;
    height: var(--player-size);
    background-size: cover;
    border-radius: 50%;
    background-color: #fff;
    border: 3px solid #FFC700;
    outline: none;
}

.featureModal.modal_content .player_avatar.overlay:active {
    background-color: #3e8e41;
    box-shadow: 2px #cfa200;
    -webkit-transform: translateY(2px);
            transform: translateY(2px);
}

.featureModal.modal_content .player_avatar.overlay.selected {
    position: relative;
}

.featureModal.modal_content .player_avatar.overlay.selected::before {
    content: '';
    position: absolute;
    top: -6px;
    left: -6px;
    width: calc(64px + 8px);
    width: calc(var(--player-size) + 8px);
    height: calc(64px + 8px);
    height: calc(var(--player-size) + 8px);
    border: 4px solid #DB2A2A;
    border-radius: 50%;
    box-shadow: 0px 0px 30px #000, 0px 0px 0px 3px #FFC700 inset;
}

.featureModal.modal_content .player_avatar .avatar_edit,
.featureModal.modal_content .edit_name {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: #fff;
    background-color: #FFB800;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: all .2s ease-in;
}

.featureModal.modal_content .player_avatar .avatar_edit:hover,
.featureModal.modal_content .edit_name:hover {
    -webkit-transform: scale(1.30);
            transform: scale(1.30);
}

.featureModal.modal_content .player_avatar.overlay:hover {
    background-blend-mode: overlay;
    background-color: #0d111f89;
}

.avatar-1 {
    background-image: url(/image/deck/avatar-1.svg) !important;
}

.avatar-2 {
    background-image: url(/image/deck/avatar-2.svg) !important;
}

.avatar-3 {
    background-image: url(/image/deck/avatar-3.svg) !important;
}

.avatar-4 {
    background-image: url(/image/deck/avatar-4.svg) !important;
}

.avatar-5 {
    background-image: url(/image/deck/avatar-5.svg);
}

.avatar-6 {
    background-image: url(/image/deck/avatar-6.png) !important;
}

.avatar-7 {
    background-image: url(/image/deck/avatar-7.png) !important;
}

.avatar-8 {
    background-image: url(/image/deck/avatar-8.png) !important;
}

.avatar-9 {
    background-image: url(/image/deck/avatar-9.png) !important;
}


.featureModal.modal_content .player_avatar span#text-avatar {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: #fff;
    font-size: 12px;
    font-size: var(--font-size-lg);
    transition: all .2s ease-in;
}

.featureModal.modal_content .player_avatar.overlay:hover span#text-avatar {
    display: block;
}

/* .featureModal.modal_content .player_avatar img {
    width: var(--player-size);
    height: var(--player-size);
    background-size: cover;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #FFC700;

}

.featureModal.modal_content .player_avatar img:active,
.featureModal.modal_content .player_avatar img:focus,
.featureModal.modal_content .player_avatar img:hover {
    border: 2px solid #FFC700;
}

.featureModal.modal_content .player_avatar .animate {
    width: var(--player-size);
    aspect-ratio: 1;
    cursor: pointer;
    transform: scale(1);
    transition: all .2s ease-in;
} */

/* .featureModal.modal_content .player_avatar .animate:hover {
    transform: scale(1.15);
} */

.featureModal.modal_content .player_profile_details {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.featureModal.modal_content .player_profile_details .edit_name {
    position: inherit;
    color: #fff;
    margin: 10px 0 0 auto;
}

.featureModal.modal_content .player_profile_details {
    font-size: 14px;
    line-height: 1.5;
    display: flex;
    margin: 5px 10px;
}

.featureModal.modal_content.profile_modal p {
    margin: 0;
}

.featureModal.modal_content.profile_modal p svg {
    margin-right: 6px;
}

.featureModal.modal_content.profile_modal p svg.text_white {
    cursor: pointer;
    margin-right: 0;
}

.verifyEmail {
    cursor: pointer;
}

.featureModal .modal_header {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border-radius: 8px 8px 0px 0px;
    padding: 10px;
    margin-bottom: 0;
}

.featureModal .modal_header .modal_title {
    font-size: 24px;
    font-weight: 600;
    line-height: 27px;
    margin: 0;
    text-transform: capitalize;
}

.modal_content .modal_body {
    margin-bottom: 0;
    padding: 16px;
}

.modal_content .modal_footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: end;
    margin-top: 16px;
}

/* .modal_content .modal_body.login_method p,
.modal_content .modal_body .bottom_line {
    color: #fff;
    letter-spacing: 0.333px;
} */
.modal_content .modal_body p,
.modal_content .modal_body .bottom_line {
    color: #fff;
    letter-spacing: 0.333px;
}

.modal_content .modal_body .bottom_line {
    margin: 8px 0 0;
}

.modal_content .modal_body.login_method p {
/* .modal_content .modal_body p { */
    color: #fff;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.333px;
    text-align: center;
    /* margin-bottom: 16px; */
    margin: 0 0 16px;
}

.modal_content .modal_body .bottom_line a {
    color: #EEC802;
    text-decoration: none;
}

.modal_content .modal_body .bottom_line a:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
}

.modal_content .modal_body .error {
    color: #AE1F24;
    font-size: 12px;
    font-size: var(--font-size-lg);
    margin-top: 5px;
}

.featureModal label {
    display: block;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.333px;
    color: white;
    margin: 0 0 8px;
}

.featureModal input {
    display: block;
    width: 100%;
    padding: .375rem 0.75rem;
    /* font-size: 1.3vw; */
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #0D6C12;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 4px;
    -webkit-appearance: none;
    appearance: none;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.featureModal input:focus {
    outline: none;
    box-shadow: none;
}

.featureModal .custom_file_input::-webkit-file-upload-button {
    visibility: hidden;
}

.featureModal .custom_file_input::before {
    /* content: 'Select some files'; */
    content: 'upload avatar';
    width: 100px;
    height: 26px;
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: none;
    border-radius: 4px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 600;
    font-size: 14px;
    padding: 0;
}

.featureModal .custom_file_input:hover::before {
    border-color: black;
}

.featureModal .custom_file_input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

/* .featureModal div { */
.featureModal .form_group {
    margin-bottom: 16px
}

/* .featureModal div:last-child { */
.featureModal .form_group:last-child {
    margin-bottom: 0;
}

.featureModal button {
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.333px;
    border-radius: 4px;
    text-transform: uppercase;
    cursor: pointer;
}

.loginOptions_btnWrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}

.loginOptions_btnWrapper button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    cursor: pointer;
    margin: 0;
}

.loginOptions_btnWrapper button svg {
    vertical-align: middle;
    margin-right: 4px;
}

.featureModal .prop_title {
    font-family: 'Poppins', sans-serif;
    font-size: 11px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0.333px;
    line-height: 27px;
    text-align: center;
    color: #FFF;
    text-transform: none;
}

.modalSetting_listProp {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.modalSetting_list {
    flex: 0 0 auto;
    width: 100%;
    margin-bottom: 16px;
}

.modalSetting_list:last-child {
    margin: 0;
}

/* ======== Switch button ========  */
.switch_toggle,
.right_side {
    margin-left: auto;
}

.featureModal .right_side button {
    font-size: 11px;
    font-weight: 600;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #000;
    background: linear-gradient(180deg, #FAD203 0%, #DBB800 100%);
    border: none;
    padding: 0 6px;
}

.featureModal .right_side button:hover {
    color: #fff;
    background: #000;
}

.featureModal .right_side button svg {
    margin-right: 3px;
}

.featureModal .switch_toggle .toggle-input {
    display: none;
}

.featureModal .switch_toggle .toggle-label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50px;
    height: 26px;
    background-color: #e0e0e0;
    border-radius: 100px;
    position: relative;
    cursor: pointer;
    margin: 0;
}

.featureModal .switch_toggle .toggle-label.checked {
    justify-content: flex-start;
}

.featureModal .switch_toggle .toggle-input:checked+.toggle-label {
    background-color: #FFD703;
}

.featureModal .switch_toggle .toggle-input:checked+.toggle-label::after,
.featureModal .switch_toggle .toggle-input:not(:checked)+.toggle-label::after {
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.333px;
    color: #000;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    padding: 0 8px;
}

.featureModal .switch_toggle .toggle-input:checked+.toggle-label::after {
    content: 'On';
    justify-content: flex-start;
}

.featureModal .switch_toggle .toggle-input:not(:checked)+.toggle-label::after {
    content: 'Off';
    left: auto;
    right: 0;
}

.featureModal .switch_toggle .toggle-label::before {
    content: "";
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translate(20%, -50%);
            transform: translate(20%, -50%);
    transition: all 0.2s ease;
}

.featureModal .switch_toggle .toggle-input:checked+.toggle-label::before {
    left: 50%;
    -webkit-transform: translate(10%, -50%);
            transform: translate(10%, -50%);
}

/* ======== Switch button end ========  */

/* Volume range Slider */

.featureModal .volume_num {
    width: 50px;
    color: #000 !important;
    background-color: #fff;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 600;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    margin-left: 100px;
    position: absolute;
    right: 0;
}

.featureModal .right_side.slider_wrap {
    width: 100%;
    max-width: 300px;
}

.featureModal .slider_container {
    position: relative;
    margin-right: 56px;
}

.featureModal .slider_container .bar {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 6px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.featureModal .slider_container .bar .fill {
    display: block;
    width: 0;
    height: 100%;
    background-color: #FFD703;
}

.featureModal .slider_container .slider {
    position: relative;
    z-index: 2;
    -webkit-appearance: none;
    width: 100%;
    height: 6px;
    border-radius: 5px;
    outline: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
}

.featureModal .slider_container .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    background-color: #FFD703;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    box-shadow: 0 0 0 0 rgba(255, 215, 3, 0.5);
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.featureModal .slider_container .slider::-webkit-slider-thumb:hover {
    box-shadow: 0 0 0 6px rgba(255, 215, 3, 0.5);
}

.featureModal .slider_container .slider:active::-webkit-slider-thumb {
    box-shadow: 0 0 0 8px rgba(255, 215, 3, 0.5);
}

/* Volume range Slider end */

.howToPlay_btn {
    display: flex;
    padding: 5px 20px;
    grid-gap: 10px;
    gap: 10px;
    border-radius: 8px;
    background: linear-gradient(180deg, #FAD203 0%, #DBB800 100%);
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: -0.333px;
    text-transform: capitalize;
    align-items: center;
    justify-content: center;
}

.power_suit_btn {
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.power_suit_btn span {
    background-image: url("/image/heart_card_icon.svg");
    height: 19px;
    width: 21px;
    background-repeat: no-repeat;
}

.partner_btn {
    position: relative;
}

.partner_btn span {
    background-image: url("/image/spade_card_icon.svg");
}

.partner_btn div {
    top: 5px;
    left: 36px;
}

.cg_table_wrapper {
    height: calc(100vh - 60px);
    max-height: 528px;
    width: 100%;
    max-width: 862px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.cg_table {
    height: calc(100% - 55px);
    height: calc(100% - var(--cg-table-margin-bottom));
    width: calc(calc(100vh - 60px - calc(100vh * 0.15)) * 2);
    max-width: calc(100% - 60px);
    max-height: 433px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cg_table_left,
.cg_table_center,
.cg_table_right {
    position: absolute;
    top: 15%;
    height: 70%;
}

.cg_table_left {
    border: 5px solid #45A729;
    border-radius: 50%;
    left: 0;
    width: 40%;
    background-color: #145F18;
}

.cg_table_center {
    border: 5px solid #45A729;
    border-left: none;
    border-right: none;
    z-index: 1;
    left: 20%;
    width: 60%;
    background-color: #145F18;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#009900", endColorstr="#367c2b", GradientType=1);
}

.cg_table_center::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 10px;
}

.cg_table_right {
    border: 5px solid #45A729;
    border-radius: 50%;
    right: 0;
    width: 40%;
    background-color: #145F18;
}

.cg_mydesk {
    position: absolute;
    bottom: 1%;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    width: 80%;
    height: 55px;
    height: var(--cg-table-margin-bottom);
    z-index: 2;
}

.cg_mycards {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: 10px;
}

.cg_mycards .cg_card {
    margin: 0 6px 4px 0;
}

.cg_player_container {
    z-index: 1;
    width: 100%;
    height: 100%;
    position: relative;
}

.cg_top_parent {
    display: flex;
    justify-content: space-evenly;
    width: calc(100% - 28%);
    height: 100%;
    margin: 0 auto;
}

.cg_top_parent .cg_player {
    position: relative;
    top: 2%;
}

.cg_player.cg_player_waiting.topRight,
.cg_player.cg_player_waiting.topLeft,
.cg_player.cg_player_waiting.bottomLeft,
.cg_player.cg_player_waiting.bottomRight {
    background-image: url("/image/deck/loader.gif");
    background-size: 38px;
}

.cg_player.bottomLeft,
.cg_player.bottomRight {
    top: auto;
    bottom: 12%;
}

.cg_player.topRight,
.cg_player.bottomRight {
    left: auto;
    /* right: 0; */
    right: 4%;
}

.cg_player.topLeft {
    background-image: url("/image/deck/avatar-8.png");
}

.cg_player.topRight {
    background-image: url("/image/deck/avatar-1.svg");
}

.cg_player.bottom {
    top: auto;
    bottom: 12%;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fff;
}

.cg_player.bottomLeft {
    background-image: url("/image/deck/avatar-3.svg");
}

.cg_player.bottomRight {
    background-image: url("/image/deck/avatar-7.png");
}

/* =========== Select Partner Card Modal  =========== */

.partner_choice_modal .modal_header {
    background: #FFFFFF;
    border-radius: 10px 10px 0px 0px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.partner_choice_modal .model_body {
    padding: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 80vh;
    max-height: 340px;
    overflow-y: auto;
}

.partner_choice_modal .model_body h5 {
    margin: 0;
}

.select_power_suit,
.select_partner_card_suit,
.select_partner_card_seq,
.select_partner_card_order {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 68%;
    padding: 10px 0px 0px;
}

.select_power_suit button,
.select_partner_card_suit button,
.select_partner_card_seq button,
.select_partner_card_order button {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0px 4px 12px;
    position: relative;
    cursor: pointer;
}

.select_power_suit button:active,
.select_partner_card_suit button:active,
.select_partner_card_seq button:active,
.select_partner_card_order button:active {
    outline: 2px solid #FFC700;
    box-shadow: 0px 0px 34px #000000;
}

.select_power_suit button.active,
.select_partner_card_suit button.active,
.select_partner_card_seq button.active,
.select_partner_card_order button.active {
    /* outline: 2px solid #30c122; */
    outline: 2px solid #FFC700;
    box-shadow: 0px 0px 34px #000000;
}

.model_footer {
    display: flex;
    justify-content: end;
    width: 100%;
    padding: 10px;
    bottom: 0;
}


/* =========== Select Partner Card Modal End  =========== */

/* Cards Style  */
.cg_table .cg_cards_wrap {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
}

/* Start player open card position */
.cg_top_parent .cg_card {
    position: absolute;
    top: calc(100% + 5%);
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.cg_player.topRight .cg_card {
    position: absolute;
    top: 100%;
    left: 0;
    -webkit-transform: translate(-65%, -10%);
            transform: translate(-65%, -10%);
}

.cg_player.topLeft .cg_card {
    position: absolute;
    top: 100%;
    right: 0;
    -webkit-transform: translate(65%, -10%);
            transform: translate(65%, -10%);
}

.cg_player.bottomRight .cg_card {
    position: absolute;
    bottom: 100%;
    left: 0;
    -webkit-transform: translate(-65%, 30%);
            transform: translate(-65%, 30%);
}

.cg_player.bottomLeft .cg_card {
    position: absolute;
    bottom: 100%;
    right: 0;
    -webkit-transform: translate(65%, 30%);
            transform: translate(65%, 30%);
}

.cg_player.bottom .cg_card {
    position: absolute;
    bottom: 100%;
    right: 0;
    -webkit-transform: translate(65%, 30%);
            transform: translate(65%, 30%);
}


/* =========== Player timer animation Start =========== */
.cg_player.cg_rounded_timer {
    width: calc(64px + 2px);
    width: calc(var(--player-size) + 2px);
    height: calc(64px + 2px);
    height: calc(var(--player-size) + 2px);
    aspect-ratio: 1;
    box-sizing: border-box;
    box-shadow: 0px 0px 60px #38FF00, 0px 0px 0px 3px white inset;
}

.cg_player.cg_rounded_timer:before {
    content: "";
    position: absolute;
    border-radius: 50%;
}

.cg_player.cg_rounded_timer:before {
    inset: 0;
    background:
        radial-gradient(farthest-side, #eb2f06 98%, #0000) top/var(--border) 4px no-repeat,
        conic-gradient(#eb2f06 calc(100*1%), #0000 0);
    background:
        radial-gradient(farthest-side, var(--color) 98%, #0000) top/var(--border) var(--border) no-repeat,
        conic-gradient(var(--color) calc(var(--rotation)*1%), #0000 0);

    -webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - 4px), #000 calc(100% - 4px));

    -webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - var(--border)), #000 calc(100% - var(--border)));
    mask: radial-gradient(farthest-side, #0000 calc(99% - 4px), #000 calc(100% - 4px));
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - var(--border)), #000 calc(100% - var(--border)));
            mask: radial-gradient(farthest-side, #0000 calc(99% - var(--border)), #000 calc(100% - var(--border)));
}

@-webkit-keyframes circlemask {
    0% {
        --rotation: 0;
        --color: #4cd137;
    }

    50% {
        --color: #4cd137;
    }

    60% {
        --color: #eb2f06;
    }

    100% {
        --color: #eb2f06;
    }
}

@keyframes circlemask {
    0% {
        --rotation: 0;
        --color: #4cd137;
    }

    50% {
        --color: #4cd137;
    }

    60% {
        --color: #eb2f06;
    }

    100% {
        --color: #eb2f06;
    }
}

@property --rotation {
    syntax: '<number>';
    inherits: true;
    initial-value: 0;
}

/* =========== Player timer animation End =========== */

/* =========== Winner Player Cards Start =========== */
.cg_winner_card {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    display: none;
}

.cg_winner_card .cg_card {
    position: absolute;
    transition: all 0.3s;
}

.cg_winner_card.cg_collecting_cards .cg_card {
    top: 50% !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -50%) !important;
            transform: translate(-50%, -50%) !important;
}

::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 14px;
    letter-spacing: 0.333px;
    font-weight: 400;
    color: #ced4da;
    opacity: 1; /* Firefox */
  }

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 14px;
    letter-spacing: 0.333px;
    font-weight: 400;
    color: #ced4da;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #ced4da;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #ced4da;
  }

/* =========== Winner Player Cards End =========== */

/* =========== Media queries =========== */

@media only screen and (max-height: 575.98px) {

    .cg_bottom_bar,
    .cg_top_bar {
        height: 30px;
    }

    .btn {
        font-size: 12px;
        font-size: var(--font-size-lg);
        line-height: 18px;
    }

    .nav_item .nav_btn {
        font-size: 18px;
        line-height: 10px;
    }

    .partner_choice_modal .model_body {
        height: 65vh;
    }
}

@media(hover: hover) and (pointer: fine) {
    .btn-primary:hover {
        background-color: #000;
        color: #fff;
    }

    .btn-add-bid:hover {
        background-color: #45A729;
    }

    .nav_btn:hover {
        color: #000;
    }
}

/* @media screen and (max-width: 600px) { */
@media all and (max-width: 768px) {

    :root {
        --card-width: 27px;
        --card-height: 38px;
        --cg-table-margin-bottom: 45px;
        --half-card-width: 30px;
        --half-card-height: 30px;
        --player-size: 46px;
        --timer-size: 44px;
        --border: 4px;
    }

    .primary_text {
        display: flex;
        align-items: center;
    }

    .player_info_name {
        font-size: 10px;
        font-size: var(--font-size-sm);
        width: 60px;
        min-width: 60px;
        height: 20px;
        bottom: -26%;
        padding: 4px 6px;
        border: 0.5px solid #fff;
    }

    .cg_card {
        font-size: 10px;
        font-size: var(--font-size-sm);
    }

    .cg_card .cg_card_icon {
        top: 34%;
    }

    .cg_card .cg_card_icon_big {
        width: 18px;
        height: 18px;
    }

    .cg_powersuit_left,
    .cg_powersuit_right {
        grid-gap: 18px;
        gap: 18px;
    }

    .cg_powersuit_left div {
        grid-gap: 12px;
        gap: 12px;
    }

    .btn {
        font-size: 10px;
        font-size: var(--font-size-sm);
        line-height: 15px;
    }

    .cg_card.cg_half_card .cg_card_icon_big {
        width: 18px;
        height: 18px;
        left: 30%;
    }

    .cg_card.cg_half_card {
        font-size: 10px;
        font-size: var(--font-size-sm);
    }

    .cg_card .cg_deck_order {
        width: 16px;
        height: 16px;
        -webkit-transform: translate(0%, -60%);
                transform: translate(0%, -60%);
    }

    .cg_bottom_bar .cg_card.cg_half_card {
        width: 27px;
        height: 27px;
        align-items: start;
    }

    .select_power_suit button,
    .select_partner_card_suit button,
    .select_partner_card_seq button,
    .select_partner_card_order button {
        margin-bottom: 8px;
    }

    .select_power_suit,
    .select_partner_card_suit,
    .select_partner_card_seq,
    .select_partner_card_order {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 68%;
        padding: 8px 0px 0px;
    }

    .cg_table .cg_cards_wrap {
        -webkit-transform: translate(-50%, -40%);
                transform: translate(-50%, -40%);
    }

    /* ========== Players avatar position ==========*/
    .cg_player.cg_player_waiting.topRight,
    .cg_player.cg_player_waiting.topLeft,
    .cg_player.cg_player_waiting.bottomLeft,
    .cg_player.cg_player_waiting.bottomRight {
        background-size: 28px;
    }

    .cg_player.bottomLeft,
    .cg_player.bottom,
    .cg_player.bottomRight {
        bottom: 8%;
    }

    .cg_player.topLeft,
    .cg_player.topRight {
        top: 6%;
    }

    .cg_player_partner_field,
    .cg_player.cg_player_opponent::after,
    .cg_player .cg_player_score {
        font-size: 8px;
        font-size: var(--font-size-xs);
        border: 2px solid #4cd137;
    }

    /* ========== Modal ========== */

    .partner_choice_modal .model_body {
        max-height: 300px;
    }

    .featureModal .modal_header .modal_title,
    .featureModal.modal_content .secondary_text {
        font-size: 18px;
    }

    .featureModal.modal_content .player_avatar {
        border: 2px solid #FFC700;
    }

    .featureModal.modal_content .avatar_container {
        grid-gap: 20px;
        gap: 20px;
    }

    .featureModal.modal_content .player_avatar.overlay.selected::before {
        top: -4px;
        left: -4px;
        width: calc(64px + 4px);
        width: calc(var(--player-size) + 4px);
        height: calc(64px + 4px);
        height: calc(var(--player-size) + 4px);
        border: 2px solid #DB2A2A;
        box-shadow: 0px 0px 30px #000, 0px 0px 0px 2px #FFC700 inset;
    }

    .featureModal.modal_content .player_avatar span#text-avatar {
        font-size: 10px;
        font-size: var(--font-size-sm);
    }
}
.cg_hide_winner_cards .cg_winner_card {
    display: none;
}

.cg_game_result {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;
    max-width: 300px;
    border-radius: 8px;
    z-index: 10;
    padding: 10px;
}

.cg_game_result .animContentWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cg_game_result.winner {
    background: linear-gradient(0deg, #136618 0%, #2E9234 53.12%);
    border: 2px solid #D6B400;
}

.cg_game_result.looser {
    background: linear-gradient(#FF2E00, #79190C);
    border: 2px solid #79190C;
}

.cg_game_result .animationIcon {
    width: 40px;
    display: inline-block;
    margin-right: 6px;
}

.cg_game_result .matchMsgBox {
    width: calc(100% - 40px);
    display: inline-block;
}

.cg_game_result .matchMsgBox h3 {
    font-size: var(--font-size-lg);
    font-weight: normal;
    letter-spacing: 0.333px;
    text-transform: uppercase;
    line-height: 16px;
    color: #FFFFFF;
    text-align: center;
    margin: 0;
}

.bubleAnimation {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
}

@media all and (max-width: 768px) {

    .cg_game_result {
        max-width: 250px;
        -webkit-transform: translate(-50%, -70%);
                transform: translate(-50%, -70%);
        padding: 6px;
    }

    .cg_game_result .matchMsgBox h3 {
        font-size: var(--font-size-sm);
    }

    .cg_game_result.winner {
        border: 1px solid #D6B400;
    }

    .cg_game_result.looser {
        border: 1px solid #79190C;
    }
}
.cg_scoretable_container {
    width: 100%;
    max-width: 960px;
    font-size: 17px;
    height: calc(100% - 50px);
}

/* ======== Common Style Start ======== */
.cg_scoretable_container table {
    width: 100%;
    overflow: visible;
    color: #fff;
    height: 100%;
}
.cg_scoretable_container table tbody {
    display: block;
    overflow-y: scroll;
    height: 100%;
}
.cg_scoretable_container table tbody::-webkit-scrollbar {
    display: none;
}
.cg_scoretable_container table tr {
    height: 50px;
    display: table;
    width: 100%;
    table-layout: fixed;
}
.cg_scoretable_container table .table_head {
    background: #fff;
    color: #000;
    position: relative;
}
.cg_scoretable_container table .table_head th:last-child:after{ 
    content: '';
    position: absolute;
    width: 21px;
    height: 68px;
    right: -21px;
    bottom: 0;
    background-repeat: no-repeat;
    background-image: url(/image/table_row_rside.svg);
}
.cg_scoretable_container table .table_head th:first-child:before{ 
    content: '';
    position: absolute;
    bottom: 0;
    width: 21px;
    left: -21px;
    height: 68px;
    background-repeat: no-repeat;
    background-image: url(/image/table_row_lside.svg);
}
.cg_scoretable_container table .looser{
    color: #fff;
    background: linear-gradient(180deg, #FF2E00 0%, #79190C 100%);
}
.cg_scoretable_container table .winner{
    color:#000; 
    background: linear-gradient(180deg, #FFD703 0%, #D6B400 100%);
}
.cg_scoretable_container th,
.cg_scoretable_container td {
    text-align: center;
    padding: 8px;
}

.cg_scoretable_container th {
    font-weight: bold;
}
/* ======== Common Style End ======== */

/* ======== Table Header Start ======== */
.cg_scoretable_container .cg_table_header {
    font-weight: 600;
} 

.cg_scoretable_container .cg_table_header .game_id{
    text-align: end;
}
.cg_scoretable_container .cg_table_header .game_id span{
    border-radius: 6px;
    padding: 6px 15px;
    margin-left: 10px;
}

.cg_scoretable_container .cg_table_header th.cg_score {
    text-align: left;
    padding-left: 36px;
}

/* ======== Table Header End ======== */

.cg_scoretable_container .hide_image,
.cg_scoretable_container .hide_text {
    display: none;
}

.cg_scoretable_container .show_image,
.cg_scoretable_container .show_text {
    display: block;
    margin: 0 auto;
}

/* ======== Table Footer Start ======== */
.cg_scoretable_container .cg_table_footer {
    background: #fff;
    color: #000;
    text-align: left;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    grid-gap: 25px;
    gap: 25px;
    border-radius: 0px 0px 6px 6px;
}
.cg_table_footer h5{
    margin: 0;
    font-size: 15px;
    text-transform: uppercase;
}
.cg_scoretable_container .cg_table_footer .cg_power_suit {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}
/* ======== Table Footer End ======== */

/* ScoreModal.css */
.overlay {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  inset: 0;
}

.content {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 960px;
  width: 100%;
  height: 520px;
  border-radius: 8px;
  padding: 0;
  border: none;
  font-size: 27px;
  background: #176D1D;
  overflow: visible;
  position: absolute;
}

.modal_btn {
  display: inline-block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #0d6efd;
  border-radius: 0.375rem;
  background-color: #0d6efd;
  transition: color .15s ease-in-out,
    background-color .15s ease-in-out,
    border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
}

.modal_btn:hover {
  color: #fff;
  background-color: #0a58ca;
  border-color: #0a58ca;
}

/* Modal Close button  */
.cg_ScoreModal_close {
  position: absolute;
  top: -15px;
  right: -15px;
  display: inline-block;
  background-color: #fff;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  text-decoration: none;
}

.cg_ScoreModal_close:hover {
  opacity: .8;
}

.cg_ScoreModal_close_icon:before,
.cg_ScoreModal_close_icon:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 14px;
  height: 3px;
  background-color: #000000;
  z-index: 1;
}

.cg_ScoreModal_close_icon:before {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
          transform: translate(-50%, -50%) rotate(45deg);
}

.cg_ScoreModal_close_icon:after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
}


@media all and (max-width: 1024px) {
  .content {
    width: 90%;
    height: 90%;
  }

  .cg_scoretable_container {
    font-size: 12px;
  }

  .cg_table_footer h5 {
    font-size: 12px;
  }
}
*,
*:after,
*:before {
    box-sizing: border-box;
}

:root {
    --cg-secondary-color: #ccc;
    --cg-third-color: rgba(0, 0, 0, .125);
    --cg-white-color: #fff;
}

body {
    background-color: #ecf0f1;
    /* Temp */
    font-family: 'Lato', sans-serif;
}

.cg_nav_container {
    width: 100%;
    max-width: 580px;
    height: 100vh;
    margin: 0 auto;
    background: #fff;
    background: var(--cg-white-color);
    /* Temp */
}

/* Style the tabs container */
.cg_nav_container .cg_nav,
.cg_nav_container .cg_footer_nav {
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 580px;
    z-index: 1;
    background: #fff;
    background: var(--cg-white-color);
}

.cg_nav_container ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

/* Style the individual tab */
.cg_nav_container .cg_nav_item {
    cursor: pointer;
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;
    padding: 10px;
}

/* Style the active tab */
.cg_nav_container .active_nav {
    background-color: #ccc;
    background-color: var(--cg-secondary-color);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

/* Style the tab content */
.cg_nav_container .cg_tab_content {
    display: none;
    padding: 10px;
    background-color: #fff;
    background-color: var(--cg-white-color);
}

:root {
    --cg-secondary-color: #ccc;
    --cg-third-color: rgba(0, 0, 0, .125);
    --cg-white-color: #fff;
    --cg-black-color: #000;
    --cg-pink-color: #fc466b;
    --cg-light-pink-color: rgb(251, 211, 233);
    --cg-dark-pink-color: rgba(187, 55, 125, 1);
    --cg-dark-pink2-color: rgba(255, 0, 132, 1);
    --cg-greenOne-color: #56ab2f;
    --cg-greenTwo-color: #a8e063;
}

.tournement_btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
            user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 6px 26px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    color: #fff;
    color: var(--cg-white-color);
    transition: .5s;
    background-size: 200% auto;
    background-image: linear-gradient(to right, #56ab2f 0%, #a8e063 51%, #56ab2f 100%);
    background-image: linear-gradient(to right, var(--cg-greenOne-color) 0%, var(--cg-greenTwo-color) 51%, var(--cg-greenOne-color) 100%);
}

.tournement_btn:hover {
    color: #fff;
    color: var(--cg-white-color);
    background-position: right center;
    text-decoration: none;
}

/* Card style start */
.cg_tournement_card {
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-color: var(--cg-white-color);
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border: 1px solid var(--cg-third-color);
    border-radius: 8px;
    margin: 16px 0;
    /* margin: 16px 10px; */
    position: relative;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.cg_tournement_card:first-child {
    margin-top: 46px;
}
.cg_tournement_card:last-child {
    margin-bottom: 16px;
}

.cg_tournement_card .top_message {
    font-size: 10px;
    letter-spacing: .5px;
    position: absolute;
    top: -8px;
    left: 8px;
    display: flex;
}


.cg_tournement_card .top_message .cg_star,
.cg_tournement_card .top_message .cg_fast,
.cg_tournement_card .top_message .cg_table_player_info {
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    color: var(--cg-white-color);
    background: rgb(251, 211, 233);
    background: var(--cg-light-pink-color);
    background: linear-gradient(360deg, rgb(251, 211, 233) 0%, rgba(187, 55, 125, 1) 51%);
    background: linear-gradient(360deg, var(--cg-light-pink-color) 0%, var(--cg-dark-pink-color) 51%);
    border: 1px solid rgba(187, 55, 125, 1);
    border: 1px solid var(--cg-dark-pink-color);
    border-radius: 3px;
    margin-right: 4px;
}

/* Star */
.cg_tournement_card .top_message .cg_star {
    width: 14px;
    border-radius: 50%;
}

.cg_tournement_card .top_message .cg_star::before {
    content: '';
    background: #fff;
    background: var(--cg-white-color);
    -webkit-clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
            clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
    display: inline-block;
    height: 10px;
    width: 10px;
}

.cg_tournement_card .top_message .cg_fast {
    padding: 0 6px;
}

.cg_tournement_card .top_message .cg_table_player_info {
    padding: 0 6px;
}


.cg_tournement_card_header {
    display: flex;
    align-items: center;
    padding: 12px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    border-bottom: 1px solid var(--cg-third-color);
    background-color: var(--cg-primary-color);
}

.cg_tournement_card_header .cg_tournement_name {
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.cg_tournement_card_header .cg_tournement_name img {
    margin-right: 4px;
}

.progress {
    width: 100%;
    height: 8px;
    border-radius: 20px;
    color: #56ab2f;
    color: var(--cg-greenOne-color);
    position: relative;
}

.progress::before {
    content: "";
    position: absolute;
    margin: 2px;
    inset: 0 100% 0 0;
    border-radius: inherit;
    background: currentColor;
    /* animation: progress-pf82op 6s infinite; */
    -webkit-animation: progress-pf82op 6s forwards;
            animation: progress-pf82op 6s forwards;
}

@-webkit-keyframes progress-pf82op {
    100% {
        inset: 0;
    }
}

@keyframes progress-pf82op {
    100% {
        inset: 0;
    }
}

.cg_tournement_card_header .cg_tournement_info {
    margin-left: auto;
}

.cg_tournement_card .cg_tournement_card_body {
    padding: 12px;
}

.cg_tournement_card .inner_content {
    display: flex;
    flex: 1 1 auto;
    min-height: 1px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.cg_tournement_card .cg_tournement_card_body .cg_tournement_card_title {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cg_tournement_card .cg_tournement_card_body span.cg_pize_amount {
    font-size: 18px;
    font-weight: bold;
    line-height: 27px;
    margin: 0 6px;
}

.cg_tournement_card .cg_tournement_card_footer {
    display: flex;
    align-items: center;
    padding: 12px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: var(--cg-primary-color);
    border-top: 1px solid rgba(0, 0, 0, .125);
    border-top: 1px solid var(--cg-third-color);
}

.cg_tournement_card .cg_tournement_card_footer .winner_prize,
.cg_tournement_card .cg_tournement_card_footer .contest_size,
.cg_tournement_card .cg_tournement_card_footer .cg_tournement_time {
    flex-grow: 1;
    flex-basis: 0;
    position: relative;
    padding-left: 18px;
}

.cg_tournement_card .cg_tournement_card_footer .winner_prize::before,
.cg_tournement_card .cg_tournement_card_footer .contest_size::before,
.cg_tournement_card .cg_tournement_card_footer .cg_tournement_time::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 14px;
    height: 14px;
    display: inline-block;
    background-image: url("/image/deck/trophy-icon.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

}

.cg_tournement_card .cg_tournement_card_footer .cg_tournement_time::before {
    background-image: url("/image/deck/clock1-icon.svg");
}

.cg_tournement_card .cg_tournement_card_footer .contest_size::before {
    background-image: url("/image/deck/people1-icon.svg");
}
/* Home.css */

/* Box Wrapper */
.cg_play_btn_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}
.cg_play_btn {
    margin: 10px;
    padding: 6px;
    width: 125px;
    height: 100px;
    background: radial-gradient(61.82% 116.88% at 46.36% 50%, #20A128 0%, #376A3A 0.01%, rgba(37, 159, 44, 0.90633) 47.92%, #22A22A 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(61.82% 116.88% at 46.36% 50%, #20A128 0%, #376A3A 0.01%, rgba(37, 159, 44, 0.90633) 47.92%, #22A22A 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    border: 2px solid #FFFFFF;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s;
}
.cg_play_btn:hover {
    box-shadow: 0px 2px 30px 0px rgba(255, 255, 255, 0.6);
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.cg_play_btn i {
    display: block;    
    height: 70%;
    background: linear-gradient(179.2deg, #242424 0.65%, #232323 0.66%, #373737 31.98%, #2C2B2B 64.22%, #070707 99.27%);
    border: 2px solid #FFFFFF;
    border-radius: 15px 15px 0px 0px;
}

.cg_play_btn  i::before {
    content: '';
    display: block;
    height: 100%;
    width: 40%;
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.cg_play_btn.play_online i::before{
    background-image: url("/image/home/play_online.svg");
}
.cg_play_btn.play_with_frnd i::before{
    background-image: url("/image/home/play_with_friend.svg");
}
.cg_play_btn.play_with_computer i::before{
    background-image: url("/image/home/play_with_computer.svg");
}
.cg_play_btn.play_tournamnet i::before{
    background-image: url("/image/home/play_tournament.svg");
}
.cg_play_btn.create_table i::before{
    background-image: url("/image/playwithfrnd/create_table.svg");
}
.cg_play_btn.join_table i::before{
    background-image: url("/image/playwithfrnd/join_table.svg");
}
.cg_play_btn span{
    position: absolute;
    left: 5%;
    right: 5%;
    bottom: 2%;
    font-weight: 800;
    font-size: 14px;
    line-height: 12px;
    height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: -0.333333px;
    text-transform: uppercase;
    color: #000000;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}

.cg_box .icon {
    width: 100%;
    height: 30px;
    margin-bottom: 12px;
}

.cg_box .title {
    font-size: 11px;
    letter-spacing: 0.5px;
    color: var(--cg-white-color);
}

/* Tabs */
.cg_play_btn_private {
    display: flex;
    justify-content: center;
}
.text_success{
    color: #0D6C12;
}
.btn_success{
    background-color: #0D6C12;
}
.btn_success:active{
    background-color: #FFC700;
    color: #000;
}
.title_text{
    font-weight: 800;
    font-size: 2.4vw;
    line-height: 27px;
    margin: 0;
    text-transform: uppercase;
}
.info_text{
    font-weight: 500;
    font-size: 1.7vw;
    line-height: 27px;
    margin: 0;
    padding-top: 20px;
}
.create_join_table {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-gap: 3.5%;
    gap: 3.5%;
    height: 100vh;
}
.bottom_bar_content h1{
    font-size: 1.2vw;
    font-weight: 400;
    line-height: 27px;
    margin: 0;
    letter-spacing: -0.33px;
    text-transform: capitalize;
}
.room_code_wrapper{
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    border: 1px solid white;
    border-radius: 8px;
    padding: 7px;
}
.room_code{
    font-weight: 700;
    font-size: 3vw;
    line-height: 37px;
    margin: 0;
}
.room_code_wrapper button{
    border: 0;
    padding: 3px 10px;
    border-radius: 7px;
    font-size: 24px;
    line-height: 14px;
}
.join_code{
    max-height: 40px;
    height: 100%;
    display: flex;
    text-align: center;
}
.join_code input{
    text-align: center;
    border-radius: 4px 0px 0px 4px;
    border: 0;
    width: 200px;
    font-weight: 700;
    font-size: 3vw;
    line-height: 37px;
}
.join_code input:focus{
    outline: none;
}
.join_code button{
    padding: 7px 20px;
    border-radius: 0px 4px 4px 0px;
    border: 0;
}
.join_code button:hover{
    cursor: pointer;
    background-color: #FFC700;
    color: #0D6C12;
}

@media(hover: hover) and (pointer: fine) {
    .btn_success:hover{
        background-color: #FFC700;
        color: #000;
    }
}
* {
    font-family: 'Poppins', sans-serif;
}

.tabs_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
}

.tabs_container {
    width: 500px;
    /* border-radius: 10px; */
    overflow: hidden;
}

.tabs {
    display: flex;
    background-color: #fff;
    /* background: linear-gradient(180deg, #FAD203 0%, #DBB800 100%); */
    /* border-radius: 10px 10px 0 0; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.tab {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.333px;
    line-height: 27px;
    color: #000;
    flex: 1 1;
    cursor: pointer;
    text-align: center;
    transition: all 0.2s ease;
    border-right: 1px solid #000;
    padding: 8px 20px;
}
.tab:last-child {
    border: none;
}
.tab.active {
    position: relative;
    /* color: #fff; */
}

.tab.active::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    border-bottom: 4px solid #DBB800;
}

.tabs_content {
    padding: 10px 0;
    height: calc(100vh - 90px);
    overflow-y: scroll;
}
.gh_card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 10px;
    margin-bottom: 16px;
}

.gh_card_header {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    margin-bottom: 0;
    border-radius: 10px 10px 0px 0px;
    background: #FFF;
}

.gh_card_header h3 {
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.333px;
    flex-grow: 1;
    margin: 0;
}

.gh_card_header .right_side {
    margin-left: auto;
    flex-grow: 0;

}

.gh_card_header .right_side .nav_item {
    display: flex;
    align-items: center;
}

.gh_card_header:first-child {
    border-radius: 10px 10px 0 0;
}

.gh_card_body {
    flex: 1 1 auto;
    padding: 10px;
    background: linear-gradient(180deg, #136618 0%, #2E9234 53.13%, #176D1D 100%);
    border-radius: 0 0 8px 8px;
}

.gh_card_title {
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.333px;
    margin: 0;
}

.gh_card_body p {
    margin: 0 0 16px;
}

.gh_card_btn {
    display: inline-block;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0.333px;
    color: #000;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    border: none;
    padding: 5px 20px;
    border-radius: 8px;
    background: linear-gradient(180deg, #FAD203 0%, #DBB800 100%);
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.gh_card_btn:hover {
    color: #fff;
    background: #000;
}

.gh_card .nav_btn {
    color: #DBB800;
}

.gh_card .nav_btn:hover {
    color: #000;
}


/* Table Style  */
.gh_table {
    border-collapse: collapse;
    width: 100%;
}

.gh_table tr {
    height: 50px;
    display: table;
    width: 100%;
    table-layout: fixed;
}

.gh_table td {
    text-align: left;
    padding: 8px;
}

.gh_table td,
.gh_table th {
    padding: 8px;
    text-align: left;
}

:root {
    --cg-screen-background: linear-gradient(180deg, #222222 0%, #373737 33.33%, #2F2D2D 68.75%, #060606 100%);
    --cg-secondary-color: #ccc;
    --cg-third-color: rgba(0, 0, 0, 0.125);
    --cg-white-color: #fff;
    --cg-black-color: #000;
}

*:after,
*:before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    background: linear-gradient(180deg, #222222 0%, #373737 33.33%, #2F2D2D 68.75%, #060606 100%);
    background: var(--cg-screen-background);
    font-family: 'Poppins', sans-serif;
}
.cg_main_container {
    position: relative;
    width: 100%;
    max-width: 700px;
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

/* Modal Styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: rgba(0, 0, 0, 0.5); */
    background: linear-gradient(180deg, rgba(34, 34, 34, 0.80) 0%, rgba(55, 55, 55, 0.80) 33.33%, rgba(47, 45, 45, 0.80) 68.75%, rgba(6, 6, 6, 0.80) 100%);
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
}

.modal .modal-content {
    background: linear-gradient(180deg, #136618 0%, #2E9234 53.12%, #176D1D 100%);
    border-radius: 0px 0px 10px 10px;
    border-radius: 5px;
    max-width: 600px;
    width: 100%;
    position: relative;
}
.modal .close {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 24px;
    padding: 4px;
    font-weight: bold;
    background-color: #FFF;
    border-radius: 50%;
    -webkit-transform: translate(30%, -30%);
            transform: translate(30%, -30%);
    cursor: pointer;
}



@media screen and (max-width: 599px) {
    body::before {
        content: "Game not supported on your device. Your screen's resolution is too low.";
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background: linear-gradient(180deg, #222222 0%, #373737 33.33%, #2F2D2D 68.75%, #060606 100%);
        background: var(--cg-screen-background);
        z-index: 9999;
        padding: 16px;
        text-align: center;
    }
    #root {
        display: none;
    }
}

