.text_success{
    color: #0D6C12;
}
.btn_success{
    background-color: #0D6C12;
}
.btn_success:active{
    background-color: #FFC700;
    color: #000;
}
.title_text{
    font-weight: 800;
    font-size: 2.4vw;
    line-height: 27px;
    margin: 0;
    text-transform: uppercase;
}
.info_text{
    font-weight: 500;
    font-size: 1.7vw;
    line-height: 27px;
    margin: 0;
    padding-top: 20px;
}
.create_join_table {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 3.5%;
    height: 100vh;
}
.bottom_bar_content h1{
    font-size: 1.2vw;
    font-weight: 400;
    line-height: 27px;
    margin: 0;
    letter-spacing: -0.33px;
    text-transform: capitalize;
}
.room_code_wrapper{
    display: flex;
    gap: 10px;
    border: 1px solid white;
    border-radius: 8px;
    padding: 7px;
}
.room_code{
    font-weight: 700;
    font-size: 3vw;
    line-height: 37px;
    margin: 0;
}
.room_code_wrapper button{
    border: 0;
    padding: 3px 10px;
    border-radius: 7px;
    font-size: 24px;
    line-height: 14px;
}
.join_code{
    max-height: 40px;
    height: 100%;
    display: flex;
    text-align: center;
}
.join_code input{
    text-align: center;
    border-radius: 4px 0px 0px 4px;
    border: 0;
    width: 200px;
    font-weight: 700;
    font-size: 3vw;
    line-height: 37px;
}
.join_code input:focus{
    outline: none;
}
.join_code button{
    padding: 7px 20px;
    border-radius: 0px 4px 4px 0px;
    border: 0;
}
.join_code button:hover{
    cursor: pointer;
    background-color: #FFC700;
    color: #0D6C12;
}

@media(hover: hover) and (pointer: fine) {
    .btn_success:hover{
        background-color: #FFC700;
        color: #000;
    }
}