.gh_card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 10px;
    margin-bottom: 16px;
}

.gh_card_header {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    margin-bottom: 0;
    border-radius: 10px 10px 0px 0px;
    background: #FFF;
}

.gh_card_header h3 {
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.333px;
    flex-grow: 1;
    margin: 0;
}

.gh_card_header .right_side {
    margin-left: auto;
    flex-grow: 0;

}

.gh_card_header .right_side .nav_item {
    display: flex;
    align-items: center;
}

.gh_card_header:first-child {
    border-radius: 10px 10px 0 0;
}

.gh_card_body {
    flex: 1 1 auto;
    padding: 10px;
    background: linear-gradient(180deg, #136618 0%, #2E9234 53.13%, #176D1D 100%);
    border-radius: 0 0 8px 8px;
}

.gh_card_title {
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.333px;
    margin: 0;
}

.gh_card_body p {
    margin: 0 0 16px;
}

.gh_card_btn {
    display: inline-block;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0.333px;
    color: #000;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    border: none;
    padding: 5px 20px;
    border-radius: 8px;
    background: linear-gradient(180deg, #FAD203 0%, #DBB800 100%);
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.gh_card_btn:hover {
    color: #fff;
    background: #000;
}

.gh_card .nav_btn {
    color: #DBB800;
}

.gh_card .nav_btn:hover {
    color: #000;
}


/* Table Style  */
.gh_table {
    border-collapse: collapse;
    width: 100%;
}

.gh_table tr {
    height: 50px;
    display: table;
    width: 100%;
    table-layout: fixed;
}

.gh_table td {
    text-align: left;
    padding: 8px;
}

.gh_table td,
.gh_table th {
    padding: 8px;
    text-align: left;
}