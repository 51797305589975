/* Home.css */

/* Box Wrapper */
.cg_play_btn_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}
.cg_play_btn {
    margin: 10px;
    padding: 6px;
    width: 125px;
    height: 100px;
    background: radial-gradient(61.82% 116.88% at 46.36% 50%, #20A128 0%, #376A3A 0.01%, rgba(37, 159, 44, 0.90633) 47.92%, #22A22A 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(61.82% 116.88% at 46.36% 50%, #20A128 0%, #376A3A 0.01%, rgba(37, 159, 44, 0.90633) 47.92%, #22A22A 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    border: 2px solid #FFFFFF;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s;
}
.cg_play_btn:hover {
    box-shadow: 0px 2px 30px 0px rgba(255, 255, 255, 0.6);
    transform: scale(1.1);
}

.cg_play_btn i {
    display: block;    
    height: 70%;
    background: linear-gradient(179.2deg, #242424 0.65%, #232323 0.66%, #373737 31.98%, #2C2B2B 64.22%, #070707 99.27%);
    border: 2px solid #FFFFFF;
    border-radius: 15px 15px 0px 0px;
}

.cg_play_btn  i::before {
    content: '';
    display: block;
    height: 100%;
    width: 40%;
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.cg_play_btn.play_online i::before{
    background-image: url("/image/home/play_online.svg");
}
.cg_play_btn.play_with_frnd i::before{
    background-image: url("/image/home/play_with_friend.svg");
}
.cg_play_btn.play_with_computer i::before{
    background-image: url("/image/home/play_with_computer.svg");
}
.cg_play_btn.play_tournamnet i::before{
    background-image: url("/image/home/play_tournament.svg");
}
.cg_play_btn.create_table i::before{
    background-image: url("/image/playwithfrnd/create_table.svg");
}
.cg_play_btn.join_table i::before{
    background-image: url("/image/playwithfrnd/join_table.svg");
}
.cg_play_btn span{
    position: absolute;
    left: 5%;
    right: 5%;
    bottom: 2%;
    font-weight: 800;
    font-size: 14px;
    line-height: 12px;
    height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: -0.333333px;
    text-transform: uppercase;
    color: #000000;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}

.cg_box .icon {
    width: 100%;
    height: 30px;
    margin-bottom: 12px;
}

.cg_box .title {
    font-size: 11px;
    letter-spacing: 0.5px;
    color: var(--cg-white-color);
}

/* Tabs */
.cg_play_btn_private {
    display: flex;
    justify-content: center;
}