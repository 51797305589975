@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Poppins:wght@400;600&family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

* {
    font-family: 'Poppins', sans-serif;
}

.tabs_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
}

.tabs_container {
    width: 500px;
    /* border-radius: 10px; */
    overflow: hidden;
}

.tabs {
    display: flex;
    background-color: #fff;
    /* background: linear-gradient(180deg, #FAD203 0%, #DBB800 100%); */
    /* border-radius: 10px 10px 0 0; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.tab {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.333px;
    line-height: 27px;
    color: #000;
    flex: 1;
    cursor: pointer;
    text-align: center;
    transition: all 0.2s ease;
    border-right: 1px solid #000;
    padding: 8px 20px;
}
.tab:last-child {
    border: none;
}
.tab.active {
    position: relative;
    /* color: #fff; */
}

.tab.active::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    border-bottom: 4px solid #DBB800;
}

.tabs_content {
    padding: 10px 0;
    height: calc(100vh - 90px);
    overflow-y: scroll;
}