/* ScoreModal.css */
.overlay {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  inset: 0;
}

.content {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 960px;
  width: 100%;
  height: 520px;
  border-radius: 8px;
  padding: 0;
  border: none;
  font-size: 27px;
  background: #176D1D;
  overflow: visible;
  position: absolute;
}

.modal_btn {
  display: inline-block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #0d6efd;
  border-radius: 0.375rem;
  background-color: #0d6efd;
  transition: color .15s ease-in-out,
    background-color .15s ease-in-out,
    border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
}

.modal_btn:hover {
  color: #fff;
  background-color: #0a58ca;
  border-color: #0a58ca;
}

/* Modal Close button  */
.cg_ScoreModal_close {
  position: absolute;
  top: -15px;
  right: -15px;
  display: inline-block;
  background-color: #fff;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  text-decoration: none;
}

.cg_ScoreModal_close:hover {
  opacity: .8;
}

.cg_ScoreModal_close_icon:before,
.cg_ScoreModal_close_icon:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 14px;
  height: 3px;
  background-color: #000000;
  z-index: 1;
}

.cg_ScoreModal_close_icon:before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.cg_ScoreModal_close_icon:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}


@media all and (max-width: 1024px) {
  .content {
    width: 90%;
    height: 90%;
  }

  .cg_scoretable_container {
    font-size: 12px;
  }

  .cg_table_footer h5 {
    font-size: 12px;
  }
}