.cg_scoretable_container {
    width: 100%;
    max-width: 960px;
    font-size: 17px;
    height: calc(100% - 50px);
}

/* ======== Common Style Start ======== */
.cg_scoretable_container table {
    width: 100%;
    overflow: visible;
    color: #fff;
    height: 100%;
}
.cg_scoretable_container table tbody {
    display: block;
    overflow-y: scroll;
    height: 100%;
}
.cg_scoretable_container table tbody::-webkit-scrollbar {
    display: none;
}
.cg_scoretable_container table tr {
    height: 50px;
    display: table;
    width: 100%;
    table-layout: fixed;
}
.cg_scoretable_container table .table_head {
    background: #fff;
    color: #000;
    position: relative;
}
.cg_scoretable_container table .table_head th:last-child:after{ 
    content: '';
    position: absolute;
    width: 21px;
    height: 68px;
    right: -21px;
    bottom: 0;
    background-repeat: no-repeat;
    background-image: url(/image/table_row_rside.svg);
}
.cg_scoretable_container table .table_head th:first-child:before{ 
    content: '';
    position: absolute;
    bottom: 0;
    width: 21px;
    left: -21px;
    height: 68px;
    background-repeat: no-repeat;
    background-image: url(/image/table_row_lside.svg);
}
.cg_scoretable_container table .looser{
    color: #fff;
    background: linear-gradient(180deg, #FF2E00 0%, #79190C 100%);
}
.cg_scoretable_container table .winner{
    color:#000; 
    background: linear-gradient(180deg, #FFD703 0%, #D6B400 100%);
}
.cg_scoretable_container th,
.cg_scoretable_container td {
    text-align: center;
    padding: 8px;
}

.cg_scoretable_container th {
    font-weight: bold;
}
/* ======== Common Style End ======== */

/* ======== Table Header Start ======== */
.cg_scoretable_container .cg_table_header {
    font-weight: 600;
} 

.cg_scoretable_container .cg_table_header .game_id{
    text-align: end;
}
.cg_scoretable_container .cg_table_header .game_id span{
    border-radius: 6px;
    padding: 6px 15px;
    margin-left: 10px;
}

.cg_scoretable_container .cg_table_header th.cg_score {
    text-align: left;
    padding-left: 36px;
}

/* ======== Table Header End ======== */

.cg_scoretable_container .hide_image,
.cg_scoretable_container .hide_text {
    display: none;
}

.cg_scoretable_container .show_image,
.cg_scoretable_container .show_text {
    display: block;
    margin: 0 auto;
}

/* ======== Table Footer Start ======== */
.cg_scoretable_container .cg_table_footer {
    background: #fff;
    color: #000;
    text-align: left;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 25px;
    border-radius: 0px 0px 6px 6px;
}
.cg_table_footer h5{
    margin: 0;
    font-size: 15px;
    text-transform: uppercase;
}
.cg_scoretable_container .cg_table_footer .cg_power_suit {
    display: flex;
    align-items: center;
    gap: 20px;
}
/* ======== Table Footer End ======== */
