.cg_hide_winner_cards .cg_winner_card {
    display: none;
}

.cg_game_result {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 300px;
    border-radius: 8px;
    z-index: 10;
    padding: 10px;
}

.cg_game_result .animContentWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cg_game_result.winner {
    background: linear-gradient(0deg, #136618 0%, #2E9234 53.12%);
    border: 2px solid #D6B400;
}

.cg_game_result.looser {
    background: linear-gradient(#FF2E00, #79190C);
    border: 2px solid #79190C;
}

.cg_game_result .animationIcon {
    width: 40px;
    display: inline-block;
    margin-right: 6px;
}

.cg_game_result .matchMsgBox {
    width: calc(100% - 40px);
    display: inline-block;
}

.cg_game_result .matchMsgBox h3 {
    font-size: var(--font-size-lg);
    font-weight: normal;
    letter-spacing: 0.333px;
    text-transform: uppercase;
    line-height: 16px;
    color: #FFFFFF;
    text-align: center;
    margin: 0;
}

.bubleAnimation {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
}

@media all and (max-width: 768px) {

    .cg_game_result {
        max-width: 250px;
        transform: translate(-50%, -70%);
        padding: 6px;
    }

    .cg_game_result .matchMsgBox h3 {
        font-size: var(--font-size-sm);
    }

    .cg_game_result.winner {
        border: 1px solid #D6B400;
    }

    .cg_game_result.looser {
        border: 1px solid #79190C;
    }
}